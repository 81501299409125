<template>
  <div :class="[`main-${id}`, 'home-class-main']">
    <div class="onhover-poster" :class="`onhover-${id}`"  id="activeCase" v-show="selected" @mouseleave.stop="actRemovePlayer">
      <div class="hover-card">
        <figure>
            <div style="height: 12rem; margin-top: 1rem;">
              <div v-if="onHoverSelectedItem.isActiveVideo" style="position:relative;" >
                <video :src="landscapeVideo" :id="`sliderVideoTrack-${id}`" loading="lazy" alt="" :poster="landscapseImage" class="image__item_video" muted autoplay loop  @click="actDetailsPage" />
                <div class="mute-unmute-btn-slider" @click="ChangeMute" v-if="landscapeVideo">
                  <img :src="ismuted ? Mute : Unmute" alt="volumn mute" />
                </div>
              </div>
                <img src="@/assets/placeholder-lands.svg" loading="lazy" class="image__item" v-else/>
            </div>
          <figcaption class="related-meta-data">
           
            <div class="related-action-btns">
              <div class="watch-now-btn">
                <p class="btn-status" @click="actDetailsPage">{{$t("WATCH NOW")}} </p>
              </div>
              <div class="add-to-watch-list">
                <CspaceWatchlistBtn :content="onHoverSelectedItem"/>
              </div>
            </div>
            <div class="related-meta-data-details" v-if="onHoverSelectedItem">
              <h1 class="fig-title">{{ onHoverSelectedItem.title }}</h1>
              <p class="fig-details">
                <span class="pg-rating" v-if="onHoverSelectedItem.advisory">{{ onHoverSelectedItem.advisory }}</span>
    
                <span class="year">{{ onHoverSelectedItem.productionyear }}</span>
                <span class="duration">{{ this.calcTimeFormat(onHoverSelectedItem.duration) }}</span>
              </p>
              <div class="fig-description" v-if="onHoverSelectedItem && onHoverSelectedItem.shortdescription">
                <!-- <span>{{onHoverSelectedItem.shortdescription}}</span> -->
                <span>{{ spliceText(onHoverSelectedItem.shortdescription,90) }}</span>
              </div>
            </div>
          </figcaption>
        </figure>
      </div>  
    </div>
    
    <div class="slider-category-title" v-if="screen && screen.title">{{ localDisplayLang === "ml" ? (screen.title.mal || screen.title.ar) : screen.title.default }}</div>

    <div class="slider-header">
      <div
        class="seeall"
        @click="seeAll(screen)"
        v-if=" ( contentList.length > 13)">
        <div> {{ $t("More") }} </div>
        <div> <img src="@/assets/icons/rightarrow.svg" alt="more" class="seeall-arrow" /> </div>
      </div> 
    </div>
    <div  :class="['swiper', `ok${id}`]" style="position: relative;padding-right: 4rem;">
      <div class="swiper-wrapper" style="padding-right: 4rem;">
        <div class="swiper-slide" style="height: 17rem;" :id="`swiper-slide-${index}`" v-for="(item, index) of contentList" :key="index" @mouseover.stop="handingTiming(true, item, $event)"  @mouseleave.stop="actRemovePlayerX">
          <img :src="getPoster(item, 'PORTRAIT')"  style="width: 100%;height:100%" @click="actDetailsPageMobile(item)"/>
        </div>
      </div>
      <div class="swiper-button-next">
        <div class="next-btn-onhover"></div>
      </div>
      
      <div class="swiper-button-prev">
        <div class="prev-btn-onhover"></div>
      </div>

     
    </div>

  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";

export default {
props: ['title', 'id', 'screenName', 'screen', 'contentList', 'counterTextV'],
data() {
  return {
    selected: false,
    selectedImage: false,
    localDisplayLang: 'en',
    onHoverSelectedItem: "",
    ismuted: true,
    Mute: require('@/assets/icons/mute.png'),
    Unmute: require('@/assets/icons/unmute.png'),
    placeholder_port: require('@/assets/placeholder-port.svg'),
    placeholder_lands: require('@/assets/placeholder-lands.svg'),
    cronjob: "",
    videoInstance: "",
    landscapseImage: "",
    landscapeVideo: "",
    posterPushed: false,
    isMobile: false,
    timerOn: ''
  }
},
components: {
      CspaceWatchlistBtn: () => import( /* webpackChunkName: "watchlisbutton" */ "@/components/SvgImages/CspaceWatchlistBtn.vue"),
  },
  mixins: [Utility],
methods: {
  spliceText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...'; // Splice text and add ellipsis
    } else {
      return text; // If text is within the limit, return it as is
    }
  },
  seeAll(screen) {
    let screenData = JSON.stringify(screen);
      localStorage.setItem("screen-list-collection", screenData);

      let currentLanguage = this.getCurrentLanguageUrlBase();


          let titleDefault = this.spacialCharEncoding(screen.title.default);
          this.$router.push({
          name: "collections",
          params: { section: titleDefault.toLowerCase(), routeObject: screen, lang: currentLanguage },
        });
      
    },
    ChangeMute() {
      this.ismuted = !this.ismuted;
      const video = document.getElementById(`sliderVideoTrack-${this.id}`);
      video.muted = !video.muted;
    },
    getVideo (contentDetails) {
      return contentDetails.trailer && contentDetails.trailer[0] && contentDetails.trailer[0].filelist[0].filename ?  contentDetails.trailer[0].filelist[0].filename : null;
    },
    getPoster(content, type) {
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content.poster.findIndex((element) => {
            return (element.quality === "SD" || element.quality === "HD") && element.postertype === type;
          });
          let img = 0;
          if (index > -1) {
            content.poster[index]?.filelist?.forEach(element => {
              if (element.quality == 'LOW') {
                // return element.filename
                img = element.filename;
              }
            });
            if (img) {
              return img
            } else {
              return this.placeholder_port;
            }
          } else {
            return this.placeholder_port;
          }
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      } else if  (content.thumbnail ) {
          return  this.placeholder_port;
        } else {
          return  this.placeholder_port;
        }
    },
    actDetailsPage () {
      this.$router.push({name: 'detailPageEarth', params:{
        title: this.onHoverSelectedItem.defaulttitle,
        contentId: this.onHoverSelectedItem.objectid
      }})
    },
    actDetailsPageMobile (item) {
      if (this.isMobile) {
        this.$router.push({name: 'detailPageEarth', params:{
        title: item.title,
        contentId: item.objectid
       }})
      }
  
    },
    calcTimeFormat(val) {
      if (!val) return;
      let totalTime = val;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") +  this.$t("hr") : "") +
        " " +
        (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "");

      if (showTime == " ") {
        return val + this.$t("secs");
      } else {
        return showTime;
      }
    },
    actRemovePlayer () {
      this.selected = false;
      this.onHoverSelectedItem.isActiveVideo = false;
    },
    actRemovePlayerX () {
      clearTimeout(this.timerOn);
    },
    handingTiming (status, item, e) {

      clearTimeout(this.timerOn);
      this.timerOn = setTimeout(() => {
          this.actOnHoverPlayerActive(status, item, e)
          console.log("Hovered for more than 2 seconds");
      }, 1200);
      
  },
  actOnHoverPlayerActive (type, item, event) {

    if (window.innerWidth < 600) {
      this.actDetailsPage();
      return;
    }
   

      this.selected = type;

      this.onHoverSelectedItem = item;
      this.landscapseImage = this.getPoster(item,'LANDSCAPE')
      this.landscapeVideo = "";
      this.landscapeVideo = this.getVideo(item)
      this.onHoverSelectedItem.isActiveVideo = false;
      this.autoPosterVideoSwitch();
      let rect = event.target.getBoundingClientRect();
      let fromLeft = rect.left;
      let mainElement = document.getElementsByClassName('main-'+this.id);  


      this.videoInstance =  document.getElementById(`sliderVideoTrack-${this.id}`);
    
      let resizing = 3;
      let isOnlySlider = (rect.height * resizing) 

      if (parseInt(window.innerWidth) > 1700) {
        isOnlySlider = (rect.height * 4 );
      }
      if (parseInt(window.innerWidth) > 1500 && parseInt(window.innerWidth) < 1680) {
        isOnlySlider = (rect.height * 3.5 );
      }


      if (this.$route.name != 'Home') {
        isOnlySlider = -10;
      }


      if (type) {
        this.ismuted = true;
        setTimeout(() => {
          let xValue = (fromLeft + window.scrollX );
          let yValue = (rect.top + window.scrollY  - ( isOnlySlider ))
          xValue = xValue == 0 ? 60 : xValue - 40;
          document.getElementsByClassName('onhover-'+this.id)[0].style.transform = "translate("+  xValue + "px," +  yValue  + "px)";
        }, 10);
      }

      document.getElementsByClassName('top-carousel-class').forEach((item) => {
        item.muted = true

      })
      eventBus.$emit('event-isMuted')

  },
  autoPosterVideoSwitch () {
    this.onHoverSelectedItem = JSON.parse(JSON.stringify(this.onHoverSelectedItem));

    setTimeout(() => {
      this.onHoverSelectedItem.isActiveVideo = true; 
    }, 1000)
  },
  cbFireScroll () {
    this.selected = false;
  }
},
mounted () {
  this.isMobile = window.innerWidth <= 600 ?  true :  false,

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    let swiper = new window.Swiper(`.ok${this.id}`, {
      slidesPerView: 2,  // Set the number of slides directly for testing
      cssMode: true,
      lazy: true,         
      spaceBetween: 10,
       navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
      // when window width is >= 320px
      400: {
        slidesPerView: 2,  // Adjust the number of posters for mobile devices
        spaceBetween: 20  // Adjust the space between posters for mobile devices
      },
      768: {
        slidesPerView: 3,  // Adjust the number of posters for mobile devices
        spaceBetween: 20  // Adjust the space between posters for mobile devices
      },
      // when window width is >= 480px
      1024: {
        slidesPerView: 4.5,
        spaceBetween: 20
      },
      // when window width is >= 640px
      1280: {
        slidesPerView: 6.5,
        spaceBetween: 10
      },
      1550: {
        slidesPerView: 8.2,
        spaceBetween: 10
      }
    },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

    // swiper.on('reachEnd', () => {
    //   setTimeout(() => {
    //     swiper.slideTo(12, 200)
    //   }, 2000)

    //   this.$emit('reachEnd');
    // });

  }
}
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
    :root {
      --blue: #1e90ff;
      --white: $clr-saina-light-dark-txtf;
      --VERTICAL-CARD-HOVER-SCALE-X: 0.8;
      --VERTICAL-CARD-HOVER-SCALE-Y: 0.8;
    }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #282828;
    /* background: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 16rem;
    border-radius: 5px;
    // pointer-events: none;
    z-index: 9;
    cursor: pointer;

  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  .slider-category-title {
    font-size: 1.3rem;
    margin: 0.8rem 0.1rem;
    color:$clr-saina-light-dark-txt;
  }

 .swiper-button-next-overlay {
    height: 15rem;
    background-image: linear-gradient(to right, rgba(0,0,0,0.85) 30%, rgba(0,0,0,0.0) 90%);
    position: absolute;
    width: 3rem;
    top: 0px;
    display: block;
    z-index: 9999;
    // pointer-events: none;
    margin-top: auto !important;
  }::after {
    position: absolute;
    top: 50%;
  }
  .swiper-button-next-overlay[aria-disabled="true"] .swiper-button-prev-overlay[aria-disabled="true"] {
    background-image: linear-gradient(to right, rgba(0,0,0,0) 30%, rgba(0,0,0,0.85) 90%);
  }
  .swiper-button-prev-overlay {
    height: 16rem;
    position: absolute;
    width: 3rem;
    top: 0px;
    right: 0;
    display: block;
    z-index: 9999;
    // pointer-events: none;
    margin-top: auto !important;
  }

  @keyframes kdUbkV2MkpUdd4wNQxnlN {
    100% {
      opacity: 1;
      transform: scale(1);

    }
    0% {
      opacity: 0;
      transform: scale(0.5);

    }
  }

.onhover-poster {
  position: absolute;
  z-index: 999;
  top: 7%;
  left: -3rem;
  cursor: pointer;
  transform-origin: center;
.hover-card {
    width: 21rem;
    height: 26rem;
    background: #101113;
    transform-origin: center center;
    animation: kdUbkV2MkpUdd4wNQxnlN 0.6s ease-in;  
    position: relative;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 3px #00000073;
    figure {
      width: 100%;
      height: 50%;
      .image__item_video {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0;
        height: 13rem;

      }
      .image__item {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0;
      }
      .related-meta-data {
        font-family: 'Roboto';
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .related-action-btns{
          display: flex;
          gap: 1rem;
          .watch-now-btn {
            width: 80%;
            height: 2.5rem;
            background: rgba(255, 255, 255, 0.9);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            color: rgba(0, 0, 0, 1);
            margin-top: 0.5rem;
            .btn-status {
              font-size: 1rem;
              font-family: 'Roboto';
              font-weight: 600;
            }
          }
          .add-to-watch-list {
            height: 2.5rem;
            width: 2.7rem !important;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .related-meta-data-details{
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .fig-title {
            font-weight: 500;
            font-size: 1.25rem;
          }
          .fig-details {
            display: flex;
            gap: 1rem;
            .pg-rating {
              background: #282828;
              border-radius: 0.25rem;
              padding: 0.1rem 0.24rem;
              font-weight: 600;
              font-size: 0.8rem;
            }
            .year, .duration{
              color: rgba(170, 170, 170, 1);
              font-weight: 400;
              font-size: 1rem;
            }
          }
          .fig-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            color: rgba(170, 170, 170, 1);
            font-weight: 400;
            font-size: 0.8rem;
            height: 3.5rem;
          }
        }
      }
    }
  }

}

.mute-unmute-btn-slider {
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  z-index: 999999;
}

.home-class-main {
  margin-left: 1rem;
}


.next-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: .2;
  border-radius: 10px;

}
.prev-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: .1;
  border-radius: 10px;
}
.slider-header {
       margin-top: 0.5rem;
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding: 0 5px 0px 0.7rem;
   
         .seeall {
           cursor: pointer;
           font-size: 1.2rem;
           font-weight: 600;
           letter-spacing: 0.75px;
           padding: 0 5px;
           transition: 0.1s;
           align-self: center;
           display: flex;
           align-items: center;
           justify-content: center;
           position: absolute;
          right: 0rem;
          margin-bottom: 3rem;
   
           &:hover {
             transform: scale(1.05);
             color: $clr-light-gd3;
           }
         }
         .seeall-arrow {
           position: relative;
           top: 0.22rem;
           width: 1rem;
           height: 1.6rem;
         }
       .slider-head {
         display: flex;
         justify-content: flex-end;
         align-items: center;
         .slider-head-btns {
           margin-top: 3px;
           .slider-controls {
             button {
               border: none;
               background: transparent;
   
               
               cursor: pointer;
               img {
                 height: 1.6rem;
                 width: auto;
               }
               &:disabled {
                 opacity: 0.3;
                 display:flex;
               }
               &:focus {
                 outline: none;
               }
             }
             &:focus {
               outline: none;
             }
           }
         }
       }
     }
     .slider-content {
       // margin-top: 1rem;
       .content-slider {
         &:focus {
           outline: none;
         }
       }
     }

</style>